import React from "react";
import { Link, withRouter } from "react-router-dom";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../js/hs.megamenu";
import "../css/hs.megamenu.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.switchLocale = this.switchLocale.bind(this);
  }
  componentDidMount() {
    if (this.props.history.location.pathname === "/en" || this.props.history.location.pathname.endsWith("-en") || this.props.history.location.pathname.endsWith("-en/")) {
      this.props.setLocale("en");
    } else {
      this.props.setLocale("fr");
    }
    $(".js-mega-menu").HSMegaMenu({
      event: "hover",
      pageContainer: $(".container"),
      breakpoint: 767.98,
      hideTimeOut: 0,
    });
  }

  switchLocale() {
    const locale= this.props.locale === "en" ? "fr" : "en";
    this.props.setLocale(locale, () => {
      const target = this.props.history.location.pathname.replace("/", "");
      const nextPage = locale === "en" ? target + "-en" : target.replace(new RegExp("-en$"), "");
      const page = this.props.pages.find(p => p.slug === nextPage);
      if (page !== undefined && target !== "") {
        this.props.history.push("/" + nextPage);
      } else {
        this.props.history.push(locale === "fr" ? "/" : "/en")
      }
    });
  }

  render() {
    const localeSwitcher = this.props.locale === "en" ? (
        <span style={{cursor: "pointer"}} onClick={this.switchLocale}>Français</span>
    ) : (
        <span style={{cursor: "pointer"}} onClick={this.switchLocale}>English</span>
    );

    const r = this.props.locale === "fr" ? (
        <li>
          <Link
              to="/sessions-de-formation-r"
              className="nav-link u-header__sub-menu-nav-link"
          >
            R
          </Link>
        </li>
    ) : null;
    const statistica = this.props.locale === "fr" ? (
        <li>
          <Link
              to="/sessions-de-formation-statistica"
              className="nav-link u-header__sub-menu-nav-link"
          >
            STATISTICA
          </Link>
        </li>
    ) : null;
    const minitab = this.props.locale === "fr" ? (
        <li>
          <Link
              to="/sessions-de-formation-minitab"
              className="nav-link u-header__sub-menu-nav-link"
          >
            MINITAB
          </Link>
        </li>
    ) : null;
    const zos = this.props.locale === "fr" ? (
        <li>
          <Link
              to="/sessions-de-formation-z-os"
              className="nav-link u-header__sub-menu-nav-link"
          >
            z/OS
          </Link>
        </li>
    ) : null;
    const formationPublique = this.props.locale === "fr" ? (
        <li>
          <Link
              to="/formation-publique"
              className="nav-link u-header__sub-menu-nav-link"
          >
            Formation publique
          </Link>
        </li>
    ) : (
      <li>
        <Link
          to="/formation-publique-en"
          className="nav-link u-header__sub-menu-nav-link"
        >
          Public Training
        </Link>
      </li>
    );
    return (
      <header
        id="header"
        className="u-header u-header-right-aligned-nav u-header--bg-transparent u-header--white-nav-links-md u-header--sub-menu-dark-bg-md u-header--abs-top"
        data-header-fix-moment="500"
        data-header-fix-effect="slide"
      >
        <div className="u-header__section">
          <div className="container-fluid px-lg-5">
            {/* <!-- Nav --> */}
            <div className="upperNavBar">
              <div className="container">
                <div className="row justify-content-end">
                  <div
                    className="col-2"
                    style={{
                      minWidth: "80px",
                      maxWidth: "80px",
                      marginRight: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    <p className="langSwitcher text-center">{localeSwitcher}</p>
                  </div>
                </div>
              </div>
            </div>
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space blancTransparent">
              <div className="u-header-center-aligned-nav__col">
                {/* <!-- Logo --> */}
                <Link
                  to={this.props.locale === "fr" ? "/" : "/en"}
                  className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                >
                  <img
                    src="/img/logo-top-left-HD.png"
                    width="114"
                    height="44"
                    style={{ paddingLeft: "15px" }}
                    alt="logo"
                  />
                </Link>
                {/* <!-- End Logo --> */}

                {/* <!-- Responsive Toggle Button --> */}
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger u-hamburger--black"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar"
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
                {/* <!-- End Responsive Toggle Button --> */}
              </div>

              {/* <!-- Navigation --> */}
              <div
                id="navBar"
                className="collapse navbar-collapse u-header__navbar-collapse"
                style={{ height: "55px" }}
              >
                <ul className="navbar-nav u-header__navbar-nav">
                  {/* <!-- Nos formations --> */}
                  <li
                    className="nav-item hs-has-sub-menu u-header__nav-item"
                    data-event="hover"
                    data-animation-in="slideInUp"
                    data-animation-out="fadeOut"
                  >
                    <a
                      id="pagesMegaMenu"
                      className="nav-link u-header__nav-link u-header__nav-link-toggle"
                      href="javascript:;"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-labelledby="pagesSubMenu"
                    >
                      {this.props.locale === "fr" ? "Nos formations" : "Our Trainings"}
                    </a>

                    {/* <!-- Nos formations - Submenu --> */}
                    <ul
                      id="pagesSubMenu"
                      className="hs-sub-menu u-header__sub-menu"
                      aria-labelledby="pagesMegaMenu"
                      style={{ minWidth: "230px" }}
                    >
                      {/* <!-- Account --> */}
                      <li className="hs-has-sub-menu">
                        <a
                          id="navLinkPagesAccount"
                          className="nav-link u-header__sub-menu-nav-link u-header__sub-menu-nav-link-toggle"
                          href="javascript:;"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-controls="navSubmenuPagesAccount"
                        >
                          {this.props.locale === "fr" ? "Nos sessions" : "Our Sessions"}
                        </a>
                        <ul
                          id="navSubmenuPagesAccount"
                          className="hs-sub-menu u-header__sub-menu"
                          aria-labelledby="navLinkPagesAccount"
                          style={{ minWidth: "230px" }}
                        >
                          {/* these sessions represent the families of courses, there will be 6 in french and 2 in english */}
                          <li>
                            <Link
                              to={this.props.locale === "fr" ? "/sas" : "/sas-en"}
                              className="nav-link u-header__sub-menu-nav-link"
                            >
                              SAS
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={this.props.locale === "fr" ? "/sessions-de-formation-spss" : "/sessions-de-formation-spss-en"}
                              className="nav-link u-header__sub-menu-nav-link"
                            >
                              SPSS
                            </Link>
                          </li>
                          {r}
                          {statistica}
                          {minitab}
                          {zos}
                          {formationPublique}
                        </ul>
                        <li>
                          <Link
                            to={this.props.locale === "fr" ? "/calendrier" : "/calendrier-en"}
                            className="nav-link u-header__sub-menu-nav-link"
                          >
                            {this.props.locale === "fr" ? "Calendrier" : "Calendar"}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={this.props.locale === "fr" ? "/formulaire-dinscription" : "/formulaire-dinscription-en"}
                            className="nav-link u-header__sub-menu-nav-link"
                          >
                            {this.props.locale === "fr" ? "Formulaire d'inscription" : "Registration Form"}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={this.props.locale === "fr" ? "/programme-rap" : "/programme-rap-en"}
                            className="nav-link u-header__sub-menu-nav-link"
                          >
                            RAP
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={this.props.locale === "fr" ? "/politiques-annulation" : "/politiques-annulation-en"}
                            className="nav-link u-header__sub-menu-nav-link"
                            href="#"
                          >
                            {this.props.locale === "fr" ? "Politique d'annulation" : "Cancellation Policy"}
                          </Link>
                        </li>
                      </li>
                      {/* <!-- End Account --> */}
                    </ul>
                    {/* <!-- End Nos formations - Submenu --> */}
                  </li>
                  {/* <!-- End Nos formations --> */}

                  {/* <!-- Nos services --> */}
                  <li
                    className="nav-item hs-has-sub-menu u-header__nav-item"
                    data-event="hover"
                    data-animation-in="slideInUp"
                    data-animation-out="fadeOut"
                  >
                    <a
                      id="pagesMegaMenu"
                      className="nav-link u-header__nav-link u-header__nav-link-toggle"
                      href="javascript:;"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-labelledby="pagesSubMenu"
                    >
                      {this.props.locale === "fr" ? "Nos services" : "Our Services"}
                    </a>

                    {/* <!-- Nos services - Submenu --> */}
                    <ul
                      id="pagesSubMenu"
                      className="hs-sub-menu u-header__sub-menu"
                      aria-labelledby="pagesMegaMenu"
                      style={{ minWidth: "230px" }}
                    >
                      {/* <!-- Account --> */}
                      <li>
                        <Link
                          to={this.props.locale === "fr" ? "/formation-en-entreprise" : "/formation-en-entreprise-en"}
                          className="nav-link u-header__sub-menu-nav-link"
                        >
                          {this.props.locale === "fr" ? "Formation en entreprise" : "On-site Training"}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={this.props.locale === "fr" ? "/service-de-consultation" : "/service-de-consultation-en"}
                          className="nav-link u-header__sub-menu-nav-link"
                        >
                          {this.props.locale === "fr" ? "Services de consultation" : "Consulting Services"}
                        </Link>
                      </li>
                      {/* <!-- End Account --> */}
                    </ul>
                    {/* <!-- End Nos services - Submenu --> */}
                  </li>
                  {/* <!-- End Nos services --> */}

                  {/* <!-- Conferences-midi --> */}
                  <li className="nav-item u-header__nav-item">
                    <Link
                      to={this.props.locale === "fr" ? "/conference-midi" : "/conference-midi-en"}
                      className="nav-link u-header__nav-link"
                    >
                      {this.props.locale === "fr" ? "Conférence en ligne" : "Online Conferences"}
                    </Link>
                  </li>
                  {/* <!-- End Conferences-midi --> */}

                  {/* <!-- Nos clients --> */}
                  <li className="nav-item u-header__nav-item">
                    <Link
                      to={this.props.locale === "fr" ? "/nos-clients" : "/nos-clients-en"}
                      className="nav-link u-header__nav-link"
                    >
                      {this.props.locale === "fr" ? "Nos clients" : "Our Clients"}
                    </Link>
                  </li>
                  {/* <!-- End Nos clients --> */}

                  <li className="nav-item u-header__nav-item">
                    <Link
                      to={this.props.locale === "fr" ? "/formateurs" : "/formateurs-en"}
                      className="nav-link u-header__nav-link"
                    >
                      {this.props.locale === "fr" ? "Équipe" : "Team"}
                    </Link>
                  </li>

                  {/* <!-- Contactez-nous --> */}
                  <li className="nav-item u-header__nav-item">
                    <Link
                      to={this.props.locale === "fr" ? "/contactez-nous" : "/contactez-nous-en"}
                      className="nav-link u-header__nav-link"
                    >
                      {this.props.locale === "fr" ? "Contactez-nous" : "Contact Us"}
                    </Link>
                  </li>
                  {/* <!-- End Contactez-nous --> */}
                </ul>
              </div>
              {/* <!-- End Navigation --> */}
            </nav>
            {/* <!-- End Nav --> */}
            {this.props.extraContent}
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
