import React from "react";

function Copyright(props) {
  const rights =
    props.locale === "en" ? "All rights reserved" : "Tous droits réservés";
  return (
    <>
      <div className="container-fluid px-lg-5">
        <div className="fondFooter"></div>
      </div>
      <div className="mt-3 mb-3">
        <p className="footerDisclaimer">
          {rights} {new Date().getFullYear()} © Hardy Services Conseils
        </p>
      </div>
    </>
  );
}

export default Copyright;
