import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const registrationForm =
    props.locale === "en" ? (
      <p className="typoBleuHardy">
        <Link to="/formulaire-dinscription-en" className="--blue">
          Registration Form
        </Link>
      </p>
    ) : (
      <p className="typoBleuHardy">
        <Link to="/formulaire-dinscription" className="--blue">
          Formulaire d'inscription
        </Link>
      </p>
    );
  const rap =
    props.locale === "en" ? (
      <p className="typoBleuHardy">
        <Link to="/programme-rap-en" className="--blue">
          RAP
        </Link>
      </p>
    ) : (
      <p className="typoBleuHardy">
        <Link to="/programme-rap" className="--blue">
          RAP
        </Link>
      </p>
    );
  const cancellationPolicy =
    props.locale === "en" ? (
      <p className="typoBleuHardy">
        <Link to="/politiques-annulation-en" className="--blue">
          Cancellation Policy
        </Link>
      </p>
    ) : (
      <p className="typoBleuHardy">
        <Link to="/politiques-annulation" className="--blue">
          Politique d'annulation
        </Link>
      </p>
    );
  const quebecAddress =
    props.locale === "en" ? (
      <div className="col-md-3 mb-3">
        <p className="typoBleuHardy">
          <strong>Quebec City</strong>
          <br />
          Jules Dallaire Complexe, Tour 1<br />
          2828, boulevard Laurier <br />
          Suite 700
          <br />
          Quebec City, Quebec  G1V 0B9
        </p>
        <p className="typoBleuHardy">
          <strong>(418) 626-1666</strong>
        </p>
      </div>
    ) : (
      <div className="col-md-3 mb-3">
        <p className="typoBleuHardy">
          <strong>Bureaux de Québec</strong>
          <br />
          Complexe Jules Dallaire Tour 1<br />
          2828, boulevard Laurier <br />
          Bureau 700
          <br />
          Québec, Qc  G1V 0B9
        </p>
        <p className="typoBleuHardy">
          <strong>(418) 626-1666</strong>
        </p>
      </div>
    );
  const montrealAddress =
    props.locale === "en" ? (
      <div className="col-md-3 mb-3">
        <p className="typoBleuHardy">
          <strong>Montreal</strong>
          <br />
          55, Moliere Street
          <br />
          Suite 115
          <br />
          Montréal, Quebec  H2R 1N7
        </p>
        <p className="typoBleuHardy">
          <strong>(514) 866-0871</strong>
        </p>
      </div>
    ) : (
      <div className="col-md-3 mb-3">
        <p className="typoBleuHardy">
          <strong>Bureaux de Montréal</strong>
          <br />
          55, rue Molière, bureau 115
          <br />
          Bureau 115
          <br />
          Montréal, Qc  H2R 1N7
        </p>
        <p className="typoBleuHardy">
          <strong>(514) 866-0871</strong>
        </p>
      </div>
    );
  return (
    <footer className="fondContenuBlancSANSBandeBleu">
      <div className="row">
        <div className="col-md-3 mb-3" style={{ minWidth: "200px" }}>
          <Link to="/">
            <img
              src="/img/logo-footer-HD.png"
              width="174"
              height="65"
              style={{ marginBottom: "30px" }}
            />
          </Link>
        </div>
        <div className="col-md-3 mb-3">
          {registrationForm}
          {rap}
          {cancellationPolicy}
        </div>
        {quebecAddress}
        {montrealAddress}
      </div>
    </footer>
  );
}

export default Footer;
