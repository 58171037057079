import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Header from "../components/header";
import Footer from "../components/footer";
import Copyright from "../components/copyright";
import Helpers from "../services/helpers";
import $ from "jquery";

ReactGA.initialize("UA-168415781-1");

class Home extends Component {
  constructor(props) {
    super(props);
    this.interceptClicks = this.interceptClicks.bind(this);
  }

  componentDidMount() {
    if (this.props.locale === "en") {
      ReactGA.pageview("/en");
    } else {
      ReactGA.pageview("/");
    }
    this.interceptClicks();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      if (this.props.locale === "en") {
        ReactGA.pageview("/en");
      } else {
        ReactGA.pageview("/");
      }
    }
    this.interceptClicks();
  }

  interceptClicks() {
    const _this = this;
    $("figure").on("click", "a", event => {
      event.preventDefault();
      let slug = "";
      try {
        const linkParts = event.target.parentElement.href.split("/");
        slug = linkParts[linkParts.length - 1];
      } catch {
        console.log("could not find slug");
      }
      _this.props.history.push(`/${slug}`);
    });
  }

  renderPage() {
    let result = "";
    if (this.props.page !== undefined) {
      result = Helpers.renderContent(this.props.page.content);
    }
    return result;
  }

  render() {
    const extraContent =
      this.props.locale === "en" ? (
        <div className="container mt-5 mb-4">
          <h4>
            Training and
            <br />
            consulting on <br />
            informational data <br />
            analysis
          </h4>
          <p className="specialites  mt-5">
            SAS <span className="filetVerticaux">|</span> SPSS{" "}
            <span className="filetVerticaux">|</span> Statistica <br />R{" "}
            <span className="filetVerticaux">|</span> Minitab
          </p>
        </div>
      ) : (
        <div className="container mt-5 mb-4">
          <h4>
            Formation et
            <br />
            conseil en analyse <br />
            de données <br />
            informationnelles
          </h4>
          <p className="specialites  mt-5">
            SAS <span className="filetVerticaux">|</span> SPSS{" "}
            <span className="filetVerticaux">|</span> Statistica <br />R{" "}
            <span className="filetVerticaux">|</span> Minitab
          </p>
        </div>
      );
    const description =
      this.props.locale === "en" ? (
        <div className="col align-self-end mt-9 fondBrunAccueil">
          <div className="container">
            <p className="texteDescription text-center">
              Hardy Consulting Services is a company dedicated to training and
              consulting on informational data analysis. <br />
              Our strength SAS, SPSS, Statistica, R and MINITAB software
              systems.
            </p>
          </div>
        </div>
      ) : (
        <div className="col align-self-end mt-9 fondBrunAccueil">
          <div className="container">
            <p className="texteDescription text-center">
              Services Conseils Hardy est une entreprise entièrement dédiée à la
              formation et au conseil en analyse de données informationnelles.{" "}
              <br />
              Notre force : SAS, SPSS, Statistica, R et Minitab.
            </p>
          </div>
        </div>
      );
    return (
      <>
        <Header
          extraContent={
            <>
              {extraContent}
              {description}
            </>
          }
          locale={this.props.locale}
          pages={this.props.pages}
          setLocale={this.props.setLocale}
        />
        <main id="content" role="main">
          {/* <!-- Main photo Section --> */}
          <div className="container-fluid px-lg-5">
            <div className=" shadow-primary-lg">
              <header
                className="masthead img-fluid animated fadeIn "
                style={{ marginTop: "20px" }}
              />
              <div className="fondContenuCremeEtBandeBleu">
                {this.renderPage()}
              </div>
              <Footer locale={this.props.locale} />
            </div>
          </div>
          <Copyright locale={this.props.locale} />
        </main>
      </>
    );
  }
}

export default withRouter(Home);
