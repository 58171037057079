import React from "react";

class Helpers {
  static renderContent(content) {
    let result = "";
    const regex = new RegExp("https://schardy.wordpress.com/", "g");
    const cleanedContent = content.rendered.replace(regex, "/");
    result = (
      <div
        dangerouslySetInnerHTML={{
          __html: cleanedContent,
        }}
      />
    );

    return result;
  }
}

export default Helpers;
