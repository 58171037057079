import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Header from "../components/header";
import Footer from "../components/footer";
import Copyright from "../components/copyright";
import Helpers from "../services/helpers";
import $ from "jquery";

ReactGA.initialize("UA-168415781-1");

class Formations extends Component {
  constructor(props) {
    super(props);
    this.interceptClicks = this.interceptClicks.bind(this);
  }

  componentDidMount() {
    if (this.props.page !== undefined) {
      ReactGA.pageview("/" + this.props.page.slug);
    }
    this.interceptClicks();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    this.interceptClicks();
    if (this.props.page !== prevProps.page) {
      ReactGA.pageview("/" + this.props.page.slug);
      window.scrollTo(0, 0);
    }
  }

  renderTitle() {
    let result = "";
    if (this.props.page !== undefined) {
      result = (
        <span
          dangerouslySetInnerHTML={{
            __html: this.props.page.title.rendered,
          }}
        />
      );
    }
    return result;
  }

  renderContent() {
    let result = "";
    if (this.props.page !== undefined) {
      result = Helpers.renderContent(this.props.page.content);
    }
    return result;
  }

  interceptClicks() {
    const _this = this;
    $("main").on("click", "a", (event) => {
      if ($(event.target).attr("href").indexOf(".") === -1) {
        event.preventDefault();
        _this.props.history.push($(event.target).attr("href"));
      }
    });
  }

  render() {
    const extraContent =
        this.props.locale === "en" ? (
            <div className="container mt-4">
              <h5 style={{ lineHeight: "1.2em" }}>
                Training and consulting on <br />
                informational data analysis
              </h5>
            </div>
        ) : (
            <div className="container mt-4">
              <h5 style={{ lineHeight: "1.2em" }}>
                Formation et conseil en analyse <br />
                de données informationnelles
              </h5>
            </div>
        );
    return (
      <>
        <Header
            extraContent={extraContent}
            locale={this.props.locale}
            pages={this.props.pages}
            setLocale={this.props.setLocale}
        />
        <main id="content" role="main">
          {/* <!-- Main photo Section --> */}
          <div className="container-fluid px-lg-5">
            <div className=" shadow-primary-lg">
              <header
                className="masthead-Short img-fluid animated fadeIn "
                style={{ marginTop: "20px" }}
              />
              <div className="fondContenuCremeEtBandeBleu">
                <h4 className="mt-3 text-center">{this.renderTitle()}</h4>
                {this.renderContent()}
              </div>
              <Footer locale={this.props.locale}/>
            </div>
          </div>
          <Copyright locale={this.props.locale} />
        </main>
      </>
    );
  }
}

export default withRouter(Formations);
