import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./pages/home";
import Page from "./pages/page";
import Formations from "./pages/formations";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "fr",
      pages: [],
      currentFormation: "404"
    };
    this.setLocale = this.setLocale.bind(this);
    this.setCurrentFormation = this.setCurrentFormation.bind(this);
    this.getPageFromSlug = this.getPageFromSlug.bind(this);
  }

  componentDidMount() {
    fetch(
      "https://public-api.wordpress.com/wp/v2/sites/schardy.wordpress.com/pages?per_page=1"
    ).then(res => {
      const numberOfItems = +res.headers.get("X-WP-Total");
      const numberOfPages = Math.ceil(numberOfItems / 20);
      const urls = [];
      for (let i = 1; i < numberOfPages + 1; i++) {
        urls.push(
          "https://public-api.wordpress.com/wp/v2/sites/schardy.wordpress.com/pages?per_page=20&page=" +
            i
        );
      }
      Promise.all(urls.map(url => fetch(url)))
        .then(res => Promise.all(res.map(r => r.json())))
        .then(results => {
          const pages = [].concat(...results);
          this.setState({ pages: pages });
        });
    });
  }

  setLocale(locale, cb) {
    this.setState({ locale: locale }, () => {
      if (cb) cb();
    });
  }

  setCurrentFormation(slug, cb) {
    this.setState({ currentFormation: slug }, () => {
      if (cb) cb();
    });
  }

  getPageFromSlug(slug) {
    return this.state.pages.find(p => p.slug === slug);
  }

  render() {
    const formations = this.state.pages.map((p, index) => (
      <Route
        key={index}
        exact
        path={`/${p.slug}`}
        render={() => (
          <Page
            locale={this.state.locale}
            page={this.getPageFromSlug(p.slug)}
            pages={this.state.pages}
            setLocale={this.setLocale}
          />
        )}
      />
    ));

    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Home
                locale={this.state.locale}
                page={this.getPageFromSlug("accueil-services-conseils-hardy")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/en"
            render={() => (
              <Home
                locale={this.state.locale}
                page={this.getPageFromSlug(
                  "accueil-services-conseils-hardy-en"
                )}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/sas"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-sas")}
                pages={this.state.pages}
                setCurrentFormation={this.setCurrentFormation}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/sas-training"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sas-training")}
                pages={this.state.pages}
                setCurrentFormation={this.setCurrentFormation}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/sessions-de-formation-spss"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-spss")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/spss-training"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("spss-training")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/sessions-de-formation-r"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-r")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/sessions-de-formation-statistica"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-statistica")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/sessions-de-formation-minitab"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-minitab")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/sessions-de-formation-z-os"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("sessions-de-formation-z-os")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/formation-publique"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("formation-publique")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/public-training"
            render={() => (
              <Formations
                locale={this.state.locale}
                page={this.getPageFromSlug("public-training")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/calendrier"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("calendrier")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/calendar"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("calendar")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/formulaire-dinscription"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("formulaire-dinscription")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/registration-form"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("registration-form")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/politiques-annulation"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("politiques-annulation")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/cancellation-policy"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("cancellation-policy")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/formation-en-entreprise"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("formation-en-entreprise")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/consulting-services"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("consulting-services")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/service-de-consultation"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("service-de-consultation")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/consulting-services"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("consulting-services")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/conference-midi"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("conference-midi")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/noon-time-conferences"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("noon-time-conferences")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/nos-clients"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("nos-clients")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/our-clients"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("our-clients")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/equipe"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("formateurs")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/team"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("team")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          <Route
            exact
            path="/contactez-nous"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("contactez-nous")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />
          <Route
            exact
            path="/contact-us"
            render={() => (
              <Page
                locale={this.state.locale}
                page={this.getPageFromSlug("contact-us")}
                pages={this.state.pages}
                setLocale={this.setLocale}
              />
            )}
          />

          {formations}
        </Switch>
      </Router>
    );
  }
}

export default App;
