import React, { Component } from "react";
import ReactGA from "react-ga";
import Header from "../components/header";
import Footer from "../components/footer";
import Copyright from "../components/copyright";
import Helpers from "../services/helpers";

ReactGA.initialize("UA-168415781-1");

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.page !== undefined) {
      ReactGA.pageview("/" + this.props.page.slug);
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      ReactGA.pageview("/" + this.props.page.slug);
      window.scrollTo(0, 0);
    }
  }

  renderTitle() {
    let result = "";
    if (this.props.page !== undefined) {
      result = (
        <span
          dangerouslySetInnerHTML={{
            __html: this.props.page.title.rendered
          }}
        />
      );
    }
    return result;
  }

  renderPage() {
    let result = "";
    if (this.props.page !== undefined) {
      result = Helpers.renderContent(this.props.page.content);
    }
    return result;
  }

  renderHeaderClasses() {
    let result = "";
    if (this.props.page !== undefined) {
      if (["service-de-consultation", "service-de-consultation-en"].indexOf(this.props.page.slug) !== -1) {
        result = "sc-masthead-Short img-fluid animated fadeIn ";
      } else if (["formation-en-entreprise", "formation-en-entreprise-en"].indexOf(this.props.page.slug) !== -1) {
        result = "fe-masthead-Short img-fluid animated fadeIn ";
      } else {
        result = "masthead-Short img-fluid animated fadeIn ";
      }
    }
    return result;
  }

  renderAdditionalStyles() {
    let result = { lineHeight: "1.2em" };
    if (this.props.page !== undefined) {
      if (
        [
          "service-de-consultation",
          "service-de-consultation-en",
          "formation-en-entreprise",
          "formation-en-entreprise-en"
        ].indexOf(this.props.page.slug) !== -1
      ) {
        result = { lineHeight: "1.2em", color: "white" };
      }
    }
    return result;
  }

  renderExtraContent() {
    let extraContent = null;
    if (this.props.page !== undefined && [
      "service-de-consultation",
      "service-de-consultation-en",
      "formation-en-entreprise",
      "formation-en-entreprise-en"
    ].indexOf(this.props.page.slug) === -1) {
      extraContent = this.props.locale === "en" ? (
        <div className="container mt-4">
          <h5 style={{ lineHeight: "1.2em" }}>
            Training and consulting on <br />
            informational data analysis
          </h5>
        </div>
      ) : (
          <div className="container mt-4">
            <h5 style={{ lineHeight: "1.2em" }}>
              Formation et conseil en analyse <br />
              de données informationnelles
          </h5>
          </div>
        );
    }
    return extraContent;
  }

  render() {
    return (
      <>
        <Header
          extraContent={this.renderExtraContent()}
          locale={this.props.locale}
          pages={this.props.pages}
          setLocale={this.props.setLocale}
        />
        <main id="content" role="main">
          {/* <!-- Main photo Section --> */}
          <div className="container-fluid px-lg-5">
            <div className=" shadow-primary-lg">
              <header
                className={this.renderHeaderClasses()}
                style={{ marginTop: "20px" }}
              />
              <div className="fondContenuCremeEtBandeBleu">
                <h4 className="mt-3 text-center">{this.renderTitle()}</h4>
                {this.renderPage()}
              </div>
              <Footer locale={this.props.locale} />
            </div>
          </div>
          <Copyright locale={this.props.locale} />
        </main>
      </>
    );
  }
}

export default Page;
